/* Variables */
/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofIOOaBTMnFcQIG.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofIMeaBTMnFcQIG.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofIOuaBTMnFcQIG.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofIO-aBTMnFcQIG.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaBTMnFcQ.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUbOvIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUZevIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUbuvIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUb-vIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevIWzgPDA.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOUbOvIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOUZevIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOUbuvIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOUb-vIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAjsOUYevIWzgPDA.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Nunito Black"), local("Nunito-Black"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAtsGUbOvIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Nunito Black"), local("Nunito-Black"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAtsGUZevIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Nunito Black"), local("Nunito-Black"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAtsGUbuvIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Nunito Black"), local("Nunito-Black"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAtsGUb-vIWzgPDEtj.woff2)
      format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Nunito Black"), local("Nunito-Black"),
    url(https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofAtsGUYevIWzgPDA.woff2)
      format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
:root {
  --primary: #fed32e !important;
  --primaryDark: #e1ba27;
  --secondary: #fe8b2e !important;
  --secondaryDark: #de7f33 !important;
  --text: #353535;
  --textLight: #575656;
  --lightGrey: #838383;
  --light: #fcfcfc;
  --input: #dfdede;
  --transition: 0.35s ease;
  --error: #f44336;
}
.cursor-pointer {
  cursor: pointer !important;
}
.zi-1 {
  z-index: 1 !important;
}
.bg--light {
  background-color: var(--light);
}
.bg--primary {
  background-color: var(--primary);
}
.text--primary {
  color: var(--primary) !important;
}
.text--primary-dark {
  color: var(--primaryDark) !important;
}
.text--light-grey {
  color: var(--lightGrey) !important;
}
.border--light-grey {
  border: 1px solid var(--input) !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}

.mb-50 {
  margin-bottom: 5rem !important;
}

.mt-50 {
  margin-top: 5rem !important;
}
.pb-50 {
  padding-bottom: 5rem !important;
}

.pt-50 {
  padding-top: 5rem !important;
}

@media (min-width: 575px) {
  html body .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
}

html {
  font-size: 62.5%;
}

/* Typography */

html body {
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
  color: var(--text);
  background: #eae9e9;
  position: relative;
}

@media (min-width: 1200px) {
  html body {
    padding-bottom: 15.2rem;
    min-height: 100vh;
  }
}

html body a {
  color: var(--primaryDark);
  transition: var(--transition);
}

html body a:hover {
  color: var(--primaryDark);
}

html body h2,
html body .h2 {
  font-size: 3.5rem;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  color: var(--text);
}

html body h3,
html body .h3 {
  font-size: 1.6rem;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
  color: #b1b1b1;
}

html body h4,
html body .h4 {
  font-size: 2.1rem;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  color: var(--textLight);
}

html body ul.dot-list {
  list-style-type: none;
  padding: 0;
}

html body ul.dot-list li {
  display: flex;
  align-items: flex-start;
}

html body ul.dot-list li::before {
  content: "";
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: var(--primary);
  display: block;
  border-radius: 50%;
  margin-right: 1rem;
  margin-top: 0.6rem;
}

html body .btn {
  font-weight: 700;
  font-size: 1.6rem;
  padding: 1.5rem 3.5rem;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* min-width: 230px; */
}

html body .btn:focus {
  box-shadow: none;
}

html body .btn--social {
  background-color: #3a496d;
  color: white;
  transition: 0.35s ease;
  border: 1px solid #3a496d;
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  padding: 0;
  min-width: 0;
}

html body .btn--social:hover {
  color: white;
  background-color: #27324c;
  border: 1px solid #27324c;
}

html body .btn--primary {
  background-color: var(--primary);
  color: var(--text);
  transition: 0.35s ease;
  border: 1px solid var(--primary);
}

html body .btn--primary:hover {
  color: white;
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
}

html body .btn--grey {
  background-color: var(--lightGrey);
  color: var(--primary);
  transition: 0.35s ease;
  border: 1px solid var(--lightGrey);
}

html body .btn--grey:hover {
  color: var(--text);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

html body .btn--secondary {
  background-color: var(--secondary);
  color: white;
  transition: 0.35s ease;
  border: 1px solid var(--secondary);
}

html body .btn--secondary:hover {
  color: white;
  background-color: var(--secondaryDark);
  border: 1px solid var(--secondaryDark);
}

html body .btn--light {
  background-color: #eae9e9;
  color: var(--text);
  transition: 0.35s ease;
  border: 1px solid #eae9e9;
}

html body .btn--light:hover {
  color: white;
  background-color: var(--textLight);
  border: 1px solid var(--textLight);
}

html body .btn--outline {
  background-color: transparent;
  color: var(--textLight);
  border: 1px solid var(--input);
  transition: 0.35s ease;
  font-weight: 400;
  font-size: 1.5rem;
  padding: 0.5rem 2.5rem;
}

html body .btn--outline:hover {
  background-color: var(--primary);
  color: var(--text);
  border: 1px solid var(--primary);
}

html body .btn--filter {
  background-color: var(--lightGrey);
  color: white;
  border: 1px solid var(--lightGrey);
  transition: 0.35s ease;
  font-weight: 400;
  font-size: 1.5rem;
  padding: 0.5rem 2.5rem;
  min-width: 0;
}

html body .btn--filter:hover {
  background-color: #3d3d3d;
  color: white;
  border: 1px solid #3d3d3d;
}

/* Material inputs */
.material-input {
  /* height: 6.5rem; */
}
.material-textarea label {
  margin-top: 2rem !important;
}
.material-input.material-textarea textarea {
  border-radius: 2.5rem !important;
}
.material-input .material-input__input {
  border: 0;
  border: 1px solid var(--input);
  font-size: 1.6rem;
  transition: var(--transition);
  background: transparent;
  z-index: 1;
  padding: 1.5rem 3rem;
  width: 100%;
  border-radius: 5rem !important;
}
.material-input .material-input__input:focus {
  box-shadow: none;
}

.input-error .material-input__error {
  color: var(--error);
  font-size: 1.2rem;
  margin-top: 5px;
  margin-left: 3rem;
}

.material-input.input-error .material-input__input,
.material-input.input-error .material-input__textarea {
  border-color: var(--error);
}

.material-input.input-error label {
  color: var(--error);
}

.material-input .material-input__input:focus {
  border: 1px solid var(--primary);
  outline: 0;
}

.material-input label {
  position: absolute;
  transition: var(--transition);
  color: var(--lightGrey);
  font-weight: 500;
  margin: 0;
  z-index: 0;
  font-size: 1.2rem;
}

@media (min-width: 575px) {
  .material-input label {
    font-size: 1.5rem;
  }
}

.required {
  color: var(--primaryDark);
}

.material-input .float-label {
  font-size: 1.2rem;
  transform-origin: left top;
  transform: translateY(-18px);
}

/* Material checkbox */
.pure-material-checkbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: black;
  font-size: 1.5rem;
  line-height: 1.6;
}

/* Input */
.pure-material-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pure-material-checkbox > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

/* Box */
.pure-material-checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 1px; /* Safari */
  border-color: var(--input);
  border-radius: 2px;
  min-width: 18px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.pure-material-checkbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(4px, 5px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pure-material-checkbox > input:checked,
.pure-material-checkbox > input:indeterminate {
  background-color: var(--primary);
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
  border-color: var(--primary);
  background-color: var(--primary);
}

.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
  border-color: white;
}

.pure-material-checkbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.pure-material-checkbox:hover > input {
  opacity: 0.04;
}

.pure-material-checkbox > input:focus {
  opacity: 0.12;
}

.pure-material-checkbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.pure-material-checkbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pure-material-checkbox > input:active + span::before {
  border-color: var(--primary);
}

.pure-material-checkbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: var(--primary);
}

/* Disabled */
.pure-material-checkbox > input:disabled {
  opacity: 0;
}

.pure-material-checkbox > input:disabled + span {
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  cursor: initial;
}

.pure-material-checkbox > input:disabled + span::before {
  border-color: currentColor;
}

.pure-material-checkbox > input:checked:disabled + span::before,
.pure-material-checkbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}

/* Material select */
.material-select {
  /* display: flex;
    align-items: center; */
}
.material-select div:first-child::after {
  content: url("./img/chevron_down_primary.svg");
  position: absolute;
  right: 3rem;
}

/* Material switch */
.pure-material-switch {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  width: 82px;
}

/* Input */
.pure-material-switch > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -6px;
  top: -13px;
  display: block;
  margin: 0;
  border-radius: 40px;
  width: 100px;
  height: 80px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s 0.1s, transform 0.2s 0.1s;
}

/* Span */
.pure-material-switch > span {
  display: inline-block;
  width: 156px;
  cursor: pointer;
  position: relative;
}

/* Track */
.pure-material-switch > span::before {
  content: "";
  display: inline-block;
  border-radius: 40px;
  width: 80px;
  height: 30px;
  background-color: #f3f3f3;
  vertical-align: top;
  transition: background-color 0.2s, opacity 0.2s;
}

/* Thumb */
.pure-material-switch > span::after {
  content: "";
  position: absolute;
  top: 5px;
  right: 121px;
  border-radius: 40px;
  width: 28px;
  height: 21px;
  background-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
  transition: background-color 0.2s, transform 0.2s;
}

/* Checked */
.pure-material-switch > input:checked {
  right: -6px;
  left: inherit;
  background-color: var(--primary);
  width: 100px;
  height: 60px;
}

.pure-material-switch > input:checked + span::before {
  background-color: #fee378;
}

.pure-material-switch > input:checked + span::after {
  background-color: var(--primary);
  transform: translateX(38px);
}

/* Hover, Focus */
.pure-material-switch:hover > input {
  opacity: 0.04;
  width: 95px;
  height: 55px;
}

.pure-material-switch > input:focus {
  opacity: 0.12;
}

.pure-material-switch:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.pure-material-switch > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pure-material-switch > input:active + span::before {
  background-color: #fee378;
}

.pure-material-switch > input:checked:active + span::before {
  background-color: #fee378;
}

/* Disabled */
.pure-material-switch > input:disabled {
  opacity: 0;
}

.pure-material-switch > input:disabled + span {
  color: rgb(var(--pure-material-onsurface-rgb, 0, 0, 0));
  opacity: 0.38;
  cursor: default;
}

.pure-material-switch > input:disabled + span::before {
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
}

.pure-material-switch > input:checked:disabled + span::before {
  background-color: rgba(var(--pure-material-primary-rgb, 33, 150, 243), 0.6);
}

/* Input file */
input[type="file"] {
  position: absolute;
  height: 100%;
  top: 0;
  opacity: 0;
  cursor: pointer;
  max-width: 100%;
}

/* Slider btns */
html body .btn--big {
  background-color: var(--textLight);
  color: white;
  transition: 0.35s ease;
  padding: 1rem 0;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  color: var(--primary);
  border-radius: 0;
  margin-right: -1px;
  width: 24rem;
  min-width: 0;
}
html body .btn--big__offers {
  font-size: 1.4rem;
  font-weight: 400;
}
html body .btn--big__text {
  padding: 0 1rem;
}

html body .btn--big__wrapper.active .btn--big {
  color: var(--text);
  background-color: var(--primary);
}

html body .btn--big__wrapper:hover .btn--big {
  color: var(--text);
  background-color: var(--primary);
}
html body .btn--big__wrapper svg path {
  transition: 0.35s ease;
}
html body .btn--big__wrapper.active .btn--big__icon path {
  fill: var(--textLight);
}
html body .btn--big__wrapper.active svg path {
  fill: var(--primary);
}
html body .btn--big__wrapper:hover .btn--big__icon path {
  fill: var(--textLight);
}
html body .btn--big__wrapper:hover svg path {
  fill: var(--primary);
}
@media (min-width: 1200px) {
  html body .btn--big {
    font-size: 2.1rem;
    min-width: 37rem;
  }
  html body .btn--big__text {
    padding: 0 6rem;
  }
  html body .btn--big {
    font-size: 2.1rem;
    min-width: 37rem;
  }
  html body .btn--big__text {
    padding: 0 6rem;
  }
}

/* info / tooltip */
html body .info {
  color: var(--primary);
  font-weight: 900;
  border-radius: 50%;
  background-color: var(--textLight);
  width: 2.2rem;
  height: 2.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Modal */
html body .modal {
  z-index: 1000001;
}
html body .modal-backdrop {
  z-index: 1000000;
}
html body .modal-content {
  border: 0;
  border-radius: 2rem;
}
.modal-close {
  position: absolute;
  right: 2rem;
  top: -1rem;
}
.modal-close img {
  width: 1rem;
}
.modal textarea {
  min-height: 15rem;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px !important;
  }
}

html body .header {
  -webkit-box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
  z-index: 1;
  position: relative;
}

html body .header__menu__item {
  margin-right: 2rem;
}

html body .header__menu__item a {
  font-size: 1.6rem;
  color: var(--text);
  transition: var(--transition);
}

html body .header__menu__item a:hover {
  opacity: 0.5;
}

html body .header__menu__item.active a {
  font-weight: 700;
}

/* Header mobile */
.header-mobile {
  z-index: 999999 !important;
}
.header-mobile .header__logo img {
  max-width: 17rem;
}
@media (min-width: 576px) {
  .header-mobile .header__logo img {
    max-width: none;
  }
}

.header-mobile__inner {
  width: 250px;
  right: -250px;
  z-index: 99;
  transition: var(--transition);
  overflow: hidden;
  top: 0;
  background-color: var(--primary);
  padding: 3rem;
  padding-top: 10rem;
  -webkit-box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
}

.header-mobile__hidden {
  overflow: auto;
  padding-bottom: 100px;
  height: 100vh;
}

.header-mobile__inner.show-menu {
  right: 0;
}

html body .header-mobile .header__menu__item a {
  color: var(--textLight);
}

.hamburger {
  background-color: white;
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 999;
  right: 15px;
  top: 15px;
  display: flex;
}
.hamburger.is-active {
  background-color: transparent;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: var(--textLight);
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: var(--textLight);
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}

.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease,
    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}

.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease,
    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (min-width: 1200px) {
  .footer,
  .footer-white {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
.footer {
  background-color: #7c7c7c;
  padding: 6rem 0;
}

.footer-white {
  background-color: transparent;
  padding: 6rem 0;
}

/* SLIDER */

html body .slider {
  background-image: url("img/home_top_mobile.jpg");
  height: 200px;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}

@media (min-width: 576px) {
  html body .slider {
    background-image: url("img/home_top.jpg");
  }
}

@media (min-width: 1200px) {
  html body .slider {
    height: 472px;
  }
  html body .slider h1 {
    font-size: 5rem !important;
  }
  html body .slider h1 .h1--subtitle {
    font-size: 2.8rem !important;
  }
}

html body .slider h1 {
  font-size: 3rem;
  font-weight: 900;
  color: var(--textLight);
}

html body .slider h1 .h1--subtitle {
  font-size: 1.8rem;
}
html body .slider__buttons {
  margin-top: -3.8rem;
}

/* END OF SLIDER */

html body .search-bar .search-bar__filter-btn.active > div {
  background: var(--primary);
  border-radius: 50%;
}
html body .search-bar .search-bar__filter-btn > div {
  transition: var(--transition);
}

/* LOGIN */
html .login .login__wrapper {
  padding: 10rem 0;
  position: relative;
}
html .login .login-content,
html .login .reset-content {
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}
html .login .login-content.active,
html .login .reset-content.active {
  opacity: 1;
  visibility: visible;
}
html .login .reset-content {
  position: absolute;
  top: 10rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1;
}
html .login h1,
html .login .h1 {
  font-size: 2.1rem;
  font-weight: 700;
}

/* END OF LOGIN */

/* REGISTER */

.register-content {
  margin-top: -4rem;
  border-radius: 2rem;
}
.btn--big__wrapper:not(.active) .btn--big {
  background-color: #dedede;
  color: #afafaf;
}
.btn--big__wrapper:not(.active) .btn--big__icon path {
  fill: #afafaf;
}
.btn--big__wrapper:not(.active) svg path {
  fill: #dedede;
}
html .register-content .btn--big__offers {
  display: none;
}

/* END OF REGISTER */

/* BREADCRUMBS */

html body .breadcrumbs {
  min-height: 12rem;
}
html body .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: var(--textLight);
  font-weight: 400;
}
html body .breadcrumb {
  background-color: transparent;
}
html body .breadcrumb .breadcrumb-item.active {
  font-weight: 700;
}
html body .breadcrumb .breadcrumb-item,
html body .breadcrumb .breadcrumb-item a {
  color: var(--textLight);
}

/* END OF BREADCRUMBS */

/* FILTERS */

@media (min-width: 1200px) {
  html body .filters {
    position: absolute;
    top: 8rem;
    background: var(--light);
    max-height: 0;
    transition: max-height 0.35s ease-out;
    overflow: auto;
    z-index: 99;
  }
  html body .filters.active {
    position: absolute;
    top: 8rem;
    width: 100%;
    background: var(--light);
    max-height: 50rem;
    transition: max-height 0.35s ease-in;
  }
}
html body .filters {
  width: 100%;
}

html body .filters ul {
  list-style: none;
}
html body .filters ul:first-child {
  padding: 0;
}
html body .filters .filters__more .filters__more-list {
  max-height: 0;
  transition: max-height 0.35s ease-out;
  overflow: hidden;
}
html body .filters .filters__more .filters__more-list.active {
  max-height: 20rem;
  transition: max-height 0.35s ease-in;
}
html body .filters .filters__more .filters__more-chevron {
  transition: var(--transition);
  transform-origin: center;
}
html body .filters .filters__more .filters__more-chevron.active {
  transform: rotate(180deg);
}

/* END OF FILTERS FILTERS */

/* MOBILE FILTER */

html body .trigger-wrapper {
  z-index: 99;
  width: 100%;
  text-align: center;
  bottom: 3rem;
}
html body .filters-trigger {
  margin: 0 auto;
  -webkit-box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 1px 10px 5px rgba(0, 0, 0, 0.08);
  opacity: 0;
  visibility: hidden;
}
.filters-mobile {
  top: 0;
  z-index: 9999999;
  height: 100vh;
  width: 100%;
  overflow: auto;
  padding-top: 10rem;
}
.filters-mobile .filters-mobile__close {
  position: absolute;
  top: 3rem;
  right: 3rem;
}

/* END OF MOBILE FILTER */

/* HEXAGONS */

#hexGrid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  /* overflow: hidden; */
  list-style-type: none;
}

.hex {
  position: relative;
  visibility: hidden;
  outline: 1px solid transparent;
  transition: var(--transition);
  /* fix for jagged edges in FF on hover transition */
}

.hex::after {
  content: "";
  display: block;
  padding-bottom: 3rem;
  /* =  100 / tan(60) * 1.5 */
}

@media (min-width: 651px) {
  .hex__in {
    position: absolute;
  }

  .hex::after {
    content: "";
    display: block;
    padding-bottom: 86.602%;
    /* =  100 / tan(60) * 1.5 */
  }
}

.hex__in {
  width: 96%;
  padding-bottom: 110.851%;
  /* =  width / sin(60) */
  margin: 0 2%;
  visibility: hidden;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}

.hex__in * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent;
  /* fix for jagged edges in FF on hover transition */
}

.hex__content * {
  position: static;
}

.hex__content {
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5rem;
}

.hex__id {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  font-weight: 900;
  color: var(--primary);
}

.hex__position {
  font-size: 2.3rem;
  margin-bottom: 1rem;
  font-weight: 900;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.hex__name {
  font-size: 2.3rem;
  color: var(--primary);
  margin-bottom: 0;
  font-weight: 900;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.hex--employee .hex__position {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 900;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.hex__category {
  font-size: 1.6rem;
  font-weight: 900;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

@media (min-width: 575px) {
  .hex__position,
  .hex__category,
  .hex__name {
    -webkit-line-clamp: 2;
  }
}

.hex__category-title {
  font-size: 1.4rem;
  margin-bottom: 0;
  font-weight: 600;
  color: #b1b1b1;
}

.hex__link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  z-index: 2;
}

/*** HEX CONTENT **********************************************************************/
.hex__bg {
  left: -100%;
  right: -100%;
  width: 100%;
  height: auto;
  margin: 0 auto;
  -webkit-transform: rotate3d(0, 0, 0, 0deg);
  -ms-transform: rotate3d(0, 0, 0, 0deg);
  transform: rotate3d(0, 0, 0, 0deg);
}

/*** HOVER EFFECT  **********************************************************************/
.hex:hover {
  transform: scale(1.03);
}

/*** HEXAGON SIZING AND EVEN ROW INDENTATION *****************************************************************/
@media (min-width: 2001px) {
  /* <- 6-5  hexagons per row */
  #hexGrid {
    padding-bottom: 4.4%;
  }

  .hex {
    width: 16.6666%;
    /* = 100 / 6 */
  }

  .hex:nth-child(11n + 7) {
    /* first hexagon of even rows */
    margin-left: 8.3333%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 2000px) and (min-width: 1601px) {
  /* <- 5-4  hexagons per row */
  #hexGrid {
    padding-bottom: 4.4%;
  }

  .hex {
    width: 20%;
    /* = 100 / 5 */
  }

  .hex:nth-child(9n + 6) {
    /* first hexagon of even rows */
    margin-left: 10%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 1600px) and (min-width: 1401px) {
  /* <- 4-3  hexagons per row */
  #hexGrid {
    padding-bottom: 4.4%;
  }

  .hex {
    width: 25%;
    /* = 100 / 4 */
  }

  .hex:nth-child(7n + 5) {
    /* first hexagon of even rows */
    margin-left: 12.5%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 1400px) and (min-width: 951px) {
  /* <- 3-2  hexagons per row */
  #hexGrid {
    padding-bottom: 5.5%;
  }

  .hex {
    width: 33.3333%;
    /* = 100 / 3 */
  }

  .hex:nth-child(5n + 4) {
    /* first hexagon of even rows */
    margin-left: 16.6666%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 950px) and (min-width: 651px) {
  /* <- 2-1  hexagons per row */
  #hexGrid {
    padding-bottom: 7.4%;
  }

  .hex {
    width: 50%;
    /* = 100 / 2 */
  }

  .hex:nth-child(3n + 3) {
    /* first hexagon of even rows */
    margin-left: 25%;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 650px) {
  /* <- 1-1  hexagons per row */
  #hexGrid {
    padding-bottom: 11.2%;
  }

  .hex {
    width: 100%;
    /* = 100 / 1 */
  }

  .hex:nth-child(2n + 2) {
    /* first hexagon of even rows */
    margin-left: 0;
    /* = width of .hex / 2  to indent even rows */
  }
}

@media (max-width: 400px) {
  #hexGrid {
    font-size: 13px;
  }
}

html body .hex__icon {
  position: absolute;
  top: 3rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* END OF HEXAGONS */

/* CARD DETAILS */
.single-card__content {
  margin-top: -4rem;
}
.single-card__content .hex__icon {
  position: static;
}
.single-card__info,
.single-card__contact {
  border-radius: 2rem;
}
.single-card__id {
  font-size: 2.8rem;
  font-weight: 900;
  color: var(--primary);
  margin: 0;
}
.single-card__skills {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}
.single-card__form {
  background: white;
  z-index: 1;
  position: relative;
  border-radius: 2rem;
  top: 0;
  display: none;
  transition: var(--transition);
}
.single-card__form.active {
  display: block;
  margin-top: -133px;
  position: relative;
}
@media (min-width: 1200px) {
  .single-card__form.active {
    max-width: 488px;
  }
}
.single-card__form-close {
  position: absolute;
  right: 2rem;
  top: -1rem;
}
.single-card__form-close img {
  width: 1rem;
}
.single-card__form textarea {
  min-height: 15rem;
}

/* END OF CARD DETAILS */

/* PAGINATION */
.hexagons__pagination {
  border-radius: 3rem;
}

.hexagons__pagination .hexagons__pagination-item {
  font-weight: 900;
  font-size: 1.6rem;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--textLight);
}

.hexagons__pagination .hexagons__pagination-item.active {
  background-color: #f2c20a;
  border-radius: 50%;
  color: white;
}
.hexagons__pagination .hexagons__pagination-prev {
  transform: rotate(180deg);
}
/* END OF PAGINATION */

/* OFFER DETAILS */
.single-card__content {
  margin-top: -4rem;
}
.single-card__content .hex__icon {
  position: static;
}
.single-card__info,
.single-card__contact {
  border-radius: 2rem;
}
.single-card__id {
  font-size: 2.8rem;
  font-weight: 900;
  color: var(--primary);
  margin: 0;
}
.single-card__about {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}
.single-card__form {
  background: white;
  z-index: 1;
  position: relative;
  border-radius: 2rem;
  top: 0;
  display: none;
  transition: var(--transition);
}
.single-card__form.active {
  display: block;
  margin-top: -133px;
  position: relative;
}
@media (min-width: 1200px) {
  .single-card__form.active {
    max-width: 488px;
  }
}
.single-card__form-close {
  position: absolute;
  right: 2rem;
  top: -1rem;
}
.single-card__form-close img {
  width: 1rem;
}
.single-card__form textarea {
  min-height: 15rem;
}
/* END OF OFFER DETAILS */

/* ADD CARD */
.add-card__content {
  margin-top: -4rem;
}
.add-card__info,
.add-card__contact {
  border-radius: 2rem;
}
.add-card__content .hex__icon {
  position: static;
  border: 1px solid var(--input);
}
/* END OF ADD CARD */

/* MY CARDS */
.my-card__content {
  margin-top: -4rem;
}
.my-card__content .hex__icon {
  position: static;
}
.my-card__info,
.my-card__contact {
  border-radius: 2rem;
}
.my-card__id {
  font-size: 2.8rem;
  font-weight: 900;
  color: var(--primary);
  margin: 0;
}
.my-card__about {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}
.my-card__status--waiting {
  color: var(--primaryDark);
}
.my-card__status--cancel {
  color: var(--error);
}
.my-card__status--success {
  color: var(--success);
}
.card-deactivated {
  opacity: 0.6;
}
/* END OF MY CARDS */

/* MY-PROFILE */
.my-profile__content {
  margin-top: -4rem;
}

.my-profile__info,
.my-profile__contact {
  border-radius: 2rem;
}

.my-profile__content .hex__icon {
  position: static;
  border: 1px solid var(--input);
}
/* END OF MY PROFILE */

/* ADMIN */
.admin-tabs {
  min-height: 12rem;
}
.nav-pills .nav-link {
  color: var(--textLight);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--textLight);
  background-color: transparent;
  font-weight: 700;
}
.admin__content {
  margin-top: -4rem;
}
.admin__content a:hover {
  text-decoration: none;
}
.admin__content__item {
  border-radius: 2rem;
}
.admin__content .hex__icon {
  position: static;
}
.admin__info,
.admin__contact {
  border-radius: 2rem;
}
.admin__id {
  font-size: 2.8rem;
  font-weight: 900;
  color: var(--primary);
  margin: 0;
}
.admin__about {
  border-top: 1px dashed black;
  border-bottom: 1px dashed black;
}
.admin__status--waiting {
  color: var(--primaryDark);
}
.admin__status--cancel {
  color: var(--error);
}
.admin__status--success {
  color: var(--success);
}
/* END OF ADMIN */

.global-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: hsl(0, 0%, 96%);
  justify-content: center;
  align-items: center;
  display: none;
}

.glboal-loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.global-loader {
  width: 65px;
}

.global-loader-wrapper.show-loader {
  display: flex;
}

/* TOAST */

.toast-wrapper {
  bottom: 0;
  left: 0;
  position: fixed;
  padding: 15px 45px 15px 45px;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100px;
  width: 100%;
  background: #b3b3b3d9;
}

.app-toast {
  padding: 15px 45px 15px 45px;
}

.app-toast span {
  font-size: 15px;
  font-weight: 400;
}

.app-toast.info-toast {
  background: #1d1d1d;
}

.app-toast.alert-toast {
  background: #ca3030;
}
