.tree-checkbox {
    border-radius: 2px;
    width: 18px;
    height: 18px;
    border: 2px solid #5a5a5a;
    cursor: pointer;
}

.tree-container{
    max-height: 400px;
    font-size: 13px;
    flex-wrap: wrap;
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    overflow-x: hidden;
}

.checkbox-wrapper{
    width: 350px;
    min-height: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 24px;
}

.checkbox-main-node-wrapper{
    display:flex;
    margin-bottom:15px;
}


.category-name{
    margin-left: 3px;
    margin-top: -3px;
    font-weight: 400;
}

.category-name.without-children {
    margin-left: 21px;
}

.tree-checkbox.checked{
    border: 2px solid #fed32e;
    background: #fed32e;
}

.checkbox-main-node-wrapper button {
    padding: 0;
    margin-top: -7px;
    margin-left: 5px;
}

.checkbox-main-node-wrapper .empty-block {
    width: 10px;
}

.checked-icon{
    margin-top: -5px;
}

.tree-filter-column{
    display: flex;
    justify-content: flex-start;
    flex: 1 1;
    align-items: center;
    flex-direction: column;
}