
.profile-form-wrapper{
    margin-top: 15px;
    margin-right: 0px !important;
    margin-left: 0px !important;
    display: flex;
    border-top: 1px solid #d6d6d6;
    padding: 24px;
    background: #f9f9f9;
    width: 100%;
    box-shadow: 8px 9px 3px -4px #dadada;
}

.profile-button-wrapper{
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.profile-button-wrapper.cv-button-wrapper{
    justify-content: flex-start;
}

.profile-button-wrapper.cv-button-wrapper button{
    margin-right: 8px;
    width:150px;
}

.profile-form-wrapper input {
    background: #f9f9f9;
}

.profile-button-wrapper button {
    height: 45px;
    width:200px;
    border-radius: 0;
    background: #3a3939;
    border: 1px solid #424242;
}

#input-cv-file{
    display:none;
}

.cv-text-wrapper{
    cursor:pointer;
    display: flex;
    background: #e6e6e6;
    justify-content: center;
    align-items: center;
    height: 45px;
    padding: 17px;
    margin-top: 16px;
    margin-right:8px;
}

.profile-container{
    margin-top
    :36px;
}

.selector-button-wrapper {
    justify-content: center;
}

.selector-button-wrapper button {
    font-size: 12px;
}

.profile-language-chips-wrapper{
    display: flex;
    flex-wrap:wrap;
}

.single-education-wrapper{
    padding:12px;
    display: flex;

}
.single-education-card{
    position:relative;
    background: #f1f1f1;
    flex: 1 1;
    padding: 24px;
    box-shadow: 1px 4px 3px -3px grey;
}

.single-education-card.active {
    background:  #dadada;
}

.education-field {
    font-size: 13px;
    color: #6b6b6b;
}

.education-value {
    font-size: 13px;
    color: #464646;
    font-weight: 500;
}

.single-education-edit-button{
    position: absolute;
    right: 24px;
    top:0;
    background: #c5c5c5;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.single-education-remove-button{
    position: absolute;
    right: 0;
    top:0;
    background: #575656;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.profie-add-education-date-wrapper{
    display: flex;
    flex-direction: row;
}

.profile-add-education-date-wrapper .form-group{
    margin-bottom:0;
} 

.add-education-date-from-wrapper{
    margin-right: 36px;
}

.single-education-clear-button{
    margin-left:12px;
}
