.offer-section-wrapper{
    margin-top: 15px;
    border-top: 1px solid #d6d6d6;
    padding: 12px;
    background: #f9f9f9;
    width: 100%;
    box-shadow: 8px 9px 3px -4px #dadada
}

.offer-section-wrapper.flexable{
    display:flex;
    flex-wrap: wrap;
}

.offer-section-wrapper.main-section {
    display: flex;
    flex-direction: row;
}

.offer-section-wrapper input { 
    background: #f9f9f9;
}

.offer-section-wrapper textarea{
    border-radius: 0;
    border-top: 1px solid white;
    background: white !important;
}

.offer-button-wrapper{
    display: flex;
    justify-content: flex-end;
}

.offer-button-wrapper button {
        height: 45px;
        width:200px;
        border-radius: 0;
        background: #3a3939;
        border: 1px solid #424242;
}

.header-name{
    margin-top:40px;
}

.editor-text-wrapper{
    font-size: 14px;
    min-height: 200px;
    padding: 8px;
    color: #495057;
    background: white;
    border: 1px solid #efefef;
}

.rdw-editor-wrapper{
    margin-top: 12px;
}

.rbt-input-wrapper input {
    border-radius: 0 !important;
    border: none !important;
    height: auto !important;
}

.offer-section-wrapper .rbt-input-multi{
    background: #f9f9f9;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #848484;
}

.offer-section-wrapper .rbt-input-multi .rbt-token {
    background-color: #ececec;
    border: 0;
    border-radius: 0;
    color: #272727;
}
.offer-section-wrapper .rbt-input-multi.focus{
    box-shadow:none;
}

.category-clear-button-wrapper {
    position: absolute;
    z-index: 50;
    top: 0;
    right: 0;
}

.category-clear-button-wrapper .category-clear-button{
    border-radius: 0 !important;
    border-radius: 0 !important;
    background: #6b6b6b !important;
    color: white !important;
    border: none !important;
    height: 37px;
    width: 37px;
    padding: 0;
    cursor:pointer;
}

.offer-section-wrapper.main-section > div > div.form-group {
    position:relative;
}

.category-clear-button-wrapper .btn-primary:focus{
    box-shadow:none;
}