.autocomplete-container{
    position:relative;
}

.autocomplete-options-wrapper{
    box-shadow: -1px 4px 10px -6px #ababab;
    position: absolute;
    z-index:200;
    width: 100%;
    max-height: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-bottom: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
    background-color: white;
}

.autocomplete-input-wrapper {
    flex-wrap: wrap;
    width: 100%;
    padding: 6px 12px -1px 12px;
    border-radius: 0;
    border: none;
    font-size: 14px;
    min-height: 45px;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: none;
    border-bottom: 1px solid #848484;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.autocomplete-placeholder{
    position: absolute;
}

.autocomplete-input-wrapper span{
    color: #8e8e8e;
}

.autocomplete-input-wrapper .autocomplete-input{
    border:none !important;
    z-index: 100;
    flex: 1;
    background:#fbfbfb00;
}

.autocomplete-input-wrapper .autocomplete-input:focus{
    outline-width: 0;
}

.autocomplete-input-wrapper:focus{
    outline: false;
}

.autocomplete-options-wrapper span{
    cursor: pointer;
    padding: .25rem 1.5rem;
}

.autocomplete-options-wrapper span:hover{
    background:#fed32e;
}

.selected-chips {
    margin-top: 4px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    background: #585858;
    color: white !important;
    padding: 6px 10px 6px 20px;
    border-radius: 50px;
    margin-right: 15px;
    cursor: pointer;
}

.cips-remove-value-wrapper{
    color:white !important;
}

.cips-remove-button-wrapper{
    margin-left: 5px;
    height: 21px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 35px;
}

.arrow-down-wrapper{
    right: -4px;
    bottom: 8px;
    position: absolute;
}