.acceptance-container {
    width: 100%;
}

.acceptance-container .tab-wrapper ul {
    padding-left: 35px;
}

.acceptance-container .tab-wrapper ul li {
    display:inline-block;
    color: #7b7b7b;
    padding: 15px 24px 15px 24px;
    cursor: pointer;
    /* border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1; */
}

.acceptance-content-wrapper{
    margin-top: 130px;
}

.acceptance-container .tab-wrapper ul li.active {
    color: #000000;
}

.acceptance-container .tab-wrapper ul li:hover{
    background: #1d1d1d;
    color: white;
}

.acceptance-container .tab-wrapper{
    background: #f1f1f1;
    box-shadow: 0px 0px 15px -3px #5f5f5f;
    margin-left: -15px !important;
    margin-right: -15px !important;
    position: fixed;
    height: 54px;
    width: 100%;
}

.acceptance-card-wrapper {
    flex:1;
    margin-top:40px;
}

.single-acceptance-card{
    border: 1px solid #e0e0e0;
    background: #f9f9f9;
    box-shadow: 0px 4px 2px -2px #b3b3b3;
    margin-bottom: 12px;
    margin-top: 12px;
    /* padding: 12px; */
    display: flex;
    flex-direction: row;
}

.single-acceptance-card:hover{
    background: #e8e8e8;
    border: 1px solid #cacaca;
}

.acceptance-card-number {
    flex-direction: column;
    text-decoration: none;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    flex: 4;
    display: flex;
    padding:12px;
    color:#575656;
}

.acceptance-card-number:hover {
    text-decoration: none;
}

.acceptance-card-button-wrapper{
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.acceptance-card-button-wrapper .acceptance-button-accept, .acceptance-card-button-wrapper .acceptance-button-accept:focus{
    font-size: 14px;
    width: 100px;
    height: 37px;
    background: #2b8629;
    border-radius: 0;
    margin-right: 15px;
    border: none;
    color: white;
}

.acceptance-card-button-wrapper .acceptance-button-reject, .acceptance-card-button-wrapper .acceptance-button-reject:focus{
    font-size: 14px;
    width: 100px;
    height: 37px;
    background: #d01010;
    border-radius: 0;
    margin-right: 15px;
    border: none;
    color: white;
}

.acceptance-card-button-wrapper button:hover{
    background:#fed32e;
}

.acceptance-card-button-wrapper button:focus {
    border: none;
    box-shadow: none;
}

.acceptance-card-label {
    color: #565656; 
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 2px;
}

.acceptance-card-text{
    font-size: 16px;
    color: #565656;
}


.admin-search-wrapper{
    position: fixed;
    background: white;
    margin-top: 50px;
    display: flex;
    height: 100px;
    margin-left: -15px;
    width: 100%;
    margin-right: -15px;
    box-shadow: 1px 4px 6px -5px grey;
    justify-content: center;
    align-items: center;
}

.admin-search-wrapper input:focus{
    background: white !important;
}