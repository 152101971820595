.card-section-wrapper{
    margin-top: 15px;
    /* display: flex; */
    /* flex-direction: column; */
    border-top: 1px solid #d6d6d6;
    padding: 24px;
    background: #f9f9f9;
    width: 100%;
    box-shadow: 8px 9px 3px -4px #dadada
}

.card-container{
    margin-top: 36px
}

.card-section-wrapper.main-section {
    display: flex;
    flex-direction: row;
}

.card-section-wrapper input { 
    background: #f9f9f9;
}

.card-section-wrapper textarea{
    border-radius: 0;
    border-top: 1px solid white;
    background: white !important;
}


.card-button-wrapper{
    display: flex;
    justify-content: flex-end;
}

.card-button-wrapper button {
        height: 45px;
        width:200px;
        border-radius: 0;
        background: #3a3939;
        border: 1px solid #424242;
}

.header-name{
    margin-top:40px;
}

.avatar-section-wrapper{
    justify-content: center;
    align-items: center;
    display: flex;
}

.avatar-section-wrapper img {
    cursor: pointer;
    border-radius: 50%;
}

.editor-text-wrapper{
    font-size: 14px;
    min-height: 200px;
    padding: 8px;
    color: #495057;
    background: white;
    border: 1px solid #efefef;
}

.rdw-editor-wrapper{
    margin-top: 12px;
}

.rbt-input-wrapper input {
    border-radius: 0 !important;
    border: none !important;
    height: auto !important;
}

.card-section-wrapper .rbt-input-multi{
    background: #f9f9f9;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #848484;
}

.card-section-wrapper .rbt-input-multi .rbt-token {
    background-color: #ececec;
    border: 0;
    border-radius: 0;
    color: #272727;
}
.card-section-wrapper .rbt-input-multi.focus{
    box-shadow:none;
}

.category-clear-button-wrapper {
    position: absolute;
    z-index: 50;
    top: 0;
    right: 0;
}

.category-clear-button-wrapper .category-clear-button{
    border-radius: 0 !important;
    border-radius: 0 !important;
    background: #6b6b6b !important;
    color: white !important;
    border: none !important;
    height: 37px;
    width: 37px;
    padding: 0;
    cursor:pointer;
}

.card-section-wrapper.main-section > div > div.form-group {
    position:relative;
}

.category-clear-button-wrapper .btn-primary:focus{
    box-shadow:none;
}

.show-switch-wrapper{
    display: flex;
    flex-direction: row;
}

.show-switch-label{
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-education-warpper{
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.card-language-warpper{
    display: flex;
    margin-top: 15px;
    flex-wrap: wrap;
}

.card-language-warpper .selected-chips{
    padding: 6px 20px 6px 20px;
    font-size: 13px;
}

.card-education-warpper.inactive{
    opacity:0.5;
}

.card-language-warpper.inactive{
    opacity:0.5;
}

.card-matches-button {
    border: 3px solid black!important;
    border-radius: 0px!important;
    margin: 0px;
    font-weight: bold!important;
    font-size: 12px!important;
    padding: 5px 12px!important;
}