.register-container{
    max-width: 400px !important;
}

.register-wrapper{
    margin-top:40px;
    box-shadow: 8px 9px 3px -4px #dadada;
    background: #f9f9f9;
}

.register-form-wrapper{
    display: flex;
    flex-direction: column;
    padding: 0px 24px 24px 24px;
    background: #f9f9f9;
    width: 100%;
  
}

form .form-group {
    margin-top: 1rem;
}

.register-form-wrapper form {
    width:100%;
}

.register-form-wrapper form input {
    background: #f9f9f9;
}

.form-control:focus{
    box-shadow: none !important;
}

.register-button-wrapper{
    display: flex;
    justify-content: flex-end;
}

.register-button-wrapper button {
    height: 45px;
    width:200px;
    border-radius: 0;
    background: #3a3939;
    border: 1px solid #424242;
}

.form-check label {
    margin-top: 18px;
    font-size: 12px;
}

.header-name{
    margin-top:40px;
}


.is-registered-message{
    font-weight: 300;
    font-size: 16px; 
    color: #55a722;
    margin-bottom: 24px;
}


.register-user-type-wrapper{
    font-size: 14px;
    background: whitesmoke;
    border-bottom: 1px solid #eaeaea;
    justify-content: center;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.register-user-type-wrapper span{
    text-align:center;
}

.register-user-type{
    letter-spacing: 1px;
    cursor: pointer;
    height: 100%;
    font-weight: 400;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.register-user-type.active{
    background: #565555;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 400;
    color: white;
}

.form-acceptance-information{
    margin-top: 7px;
    font-size: 12px;
    color: #6d6d6d;
    padding-right: 10px;
    text-align: justify;
}

.password-requirements {
    font-size: 11px;
    color: #6c757d!important;
}