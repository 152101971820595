.filter-wrapper{
    position: fixed;
    z-index: 100;
    margin-left: -15px !important;
    margin-right: 0 !important;
    box-shadow: 0px 1px 7px -1px grey;
    width: 100%;
    background-color: #fbfbfb;
    padding: 0px 24px 24px 24px;
    justify-content: center;
    align-items: center;
}

/* filter for mobile */

.mobile-filter-wrapper{
    display: flex;
    flex-direction: column;
    top: 0;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    background: white;
}

.mobile-filter-wrapper .mobile-filter-input-wrapper {
    width: 100%;
    padding: 10px;
}

.mobile-tree-wrapper{
    padding: 0px 10px 10px 10px;
    flex:1;
    display: flex;
    padding: 10px;
    width: 100%;
    height: 66px;
}

.mobile-filter-wrapper .mobile-filter-input-wrapper .autocomplete-input:focus{
    background:#ffffff00 !important;
}

.mobile-filter-wrapper button {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background: #3a3939;
    border: 1px solid #424242;
}

.mobile-tree-wrapper {
    padding: 10px;
}

.mobile-tree-wrapper .tree-container .checkbox-main-node-wrapper button {
    background: none !important;
    border: none !important;
    margin-top: -1px;
}

.mobile-tree-wrapper .tree-container .checkbox-main-node-wrapper .category-name {
    margin-top: -1px;
}

.mobile-tree-wrapper > .tree-container > .tree-filter-column {
    align-items: flex-start !important;
}

.mobile-tree-wrapper > .tree-container > .tree-filter-column:first-child {
    padding-top: 10px !important;
}

.mobile-tree-wrapper > .tree-container > .tree-filter-column > .checkbox-wrapper {
    margin-left: 0px !important;
}

.mobile-filter-input-wrapper.clear-button button{
    float: right;
    margin-top: 26px;
    margin-right: 5px;
}

.mobile-show-filter-button-wrapper button {
    box-shadow: 1px 1px 18px -5px #252525;
    font-size: 13px;
    width: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background: #3a3939;
    border: 1px solid #424242;
}

.mobile-show-filter-button-wrapper {
    background: #ffffffd1;
    left: 0;
    display: flex;
    right: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 20;
    height: 60px;
    justify-content: center;
    align-items: center;
}

.mobile-filter-input-wrapper.clear-button{
    height:42px;
    padding-top: 0px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.mobile-search-filter-wrapper{
    margin-top: 24px;
    border-top: 1px solid #e6e6e6;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 60px;
}

/* end filter for mobile */

.filter-wrapper input {
    background: #fbfbfb;
}

.filter-wrapper form, .filter-wrapper .accordion {
    width:100%;
}

.default-filter-wrapper {
    align-items: flex-end;
}

.filter-wrapper .default-filter-wrapper button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    background: #1d1d1d;
    border: 1px solid #1d1d1d;
    border-radius: 24px;
    font-size: 14px;
}

.filter-wrapper .default-filter-wrapper button:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 46px;
    border-radius: 0;
    background: #1d1d1d;
    border: 1px solid #1d1d1d;
    border-radius: 50px;
}

.filter-wrapper .col{
    width:200px;
    margin-bottom: 24px;
    display: flex;
}

.advenced-filter-wrapper {
    border-top: 1px solid rgb(226, 226, 226);
    margin-top: 24px;
    padding-top: 31px;
    padding-bottom: 15px;

    max-height:500px;
}

.filter-inputs-wrapper{
    padding-left:24px;
    padding-right: 24px;
}

.filter-button-wrapper button:first-child{
    margin-right:15px;
}

.filter-button-wrapper{
    display: flex;
    justify-content: flex-start;
}

.filter-col{
    margin-top:24px;
}


.close-filter-button-wrapper{
    margin-top: 10px;
    padding-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
}

.close-filter-button-wrapper button {
    height: 45px;
    width: 200px;
    border-radius: 40px;
    background: #3a3939;
    border: 1px solid #424242;
}

.search-type-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-single-type{
    background: #f3f3f3;
    flex: 1;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:black;
    cursor:pointer;
}

.search-single-type:hover{
    color:black;
    background: #fed32e;
}

.search-single-type.active{
    background: #1d1d1d;
    color: white;
}