.login-container{
    max-width: 400px !important;
}

.login-wrapper{
    margin-top:40px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #d6d6d6;
    padding: 24px;
    background: #f9f9f9;
    width: 100%;
    box-shadow: 8px 9px 3px -4px #dadada
}

form .form-group {
    margin-top: 1rem;
}

.login-wrapper form {
    width:100%;
}

.login-wrapper form input {
    background: #f9f9f9;
}

.form-control:focus{
    box-shadow: none !important;
}

.login-button-wrapper{
    display: flex;
    justify-content: flex-end;
}

.login-button-wrapper button {
    height: 45px;
    width:200px;
    border-radius: 0;
    background: #3a3939;
    border: 1px solid #424242;
}

.header-name{
    margin-top:40px;
}

.token-is-valid{
    font-weight: 300;
    font-size: 16px;
    color: #55a722;
    margin-bottom: 24px;
}

.token-no-valid{
    font-weight: 300;
    font-size: 16px;
    color: red;
    margin-bottom: 24px;
}

.login-reminder-button-wrapper{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 14px;
    font-size: 13px;
}

.login-reminder-button-wrapper a {
    color: #080808;
}

.login-reminder-button-wrapper a:hover{
    color: #080808;
    text-decoration: none;
}